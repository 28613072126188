import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { TagModel } from '@/models/post-model'
import { ProfileModel } from '@/models/profile-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, FollowTypeEnum } from '@/stores/dispatcher'
import { PostsStore } from '@/stores/posts-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class SummaryViewModel {
  @observable posts?: PostsStore
  @observable userProfile?: ProfileModel
  @observable fetching = false
  @observable mostLikedBys: ProfileModel[] = []
  @observable topTags: TagModel[] = []
  @observable mostLikedByLoading = false
  @observable topTagLoading = false
  @observable topLinks: any[] = []
  @observable topLinkLoading = false
  @observable givenLike = 0
  @observable receivedLike = 0
  @observable daosOwned: any[] = []
  @observable daoOwnedFetching = false
  @observable daoContributed: any[] = []
  @observable daoContributedFetching = false
  private _unsubcrible = new Subject()
  private _disposers: IReactionDisposer[]

  constructor() {
    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
    ]
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (profile.type === FollowTypeEnum.user) {
      const userIndex = this.mostLikedBys.findIndex((item) => item._id === profile.to)
      if (userIndex !== -1) {
        this.mostLikedBys[userIndex] = {
          ...this.mostLikedBys[userIndex],
          isFollowing: profile.followState,
          loading: false,
        }
      }
    } else if (profile.type === FollowTypeEnum.tag) {
      const tagIndex = this.topTags.findIndex((item) => item.id === profile.to)
      if (tagIndex !== -1) {
        const tag = this.topTags[tagIndex]
        this.topTags[tagIndex] = {
          ...tag,
          isFollowing: profile.followState,
          totalFollowers: getFollowStatsNumberAfterSync(tag.totalFollowers, profile.followState),
          loading: false,
        }
      }
    }
  }

  destroy() {
    this._disposers?.forEach((d) => d())
    this.posts?.destroy()
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @asyncAction *init() {
    try {
      this.fetching = true
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        this.fetchPosts()
        this.fetchMostUserLiked()
        this.fetchTopTags()
        this.fetchTopLink()
        // this.fetchLikeInformation()
        this.fetchDaosOwned()
        this.fetchDaosContributed()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @action.bound fetchPosts() {
    const filter = { _limit: 12, _start: 0, profile: this.userProfile?._id, _sort: 'createdAt:desc' }
    this.posts = new PostsStore(filter)
  }

  @asyncAction *fetchMostUserLiked() {
    try {
      this.mostLikedByLoading = true
      if (this.userProfile?._id) {
        const res = yield apiService.likes.getMostUserLikedByProfile(this.userProfile?._id)
        if (res) this.mostLikedBys = res
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.mostLikedByLoading = false
    }
  }

  @asyncAction *fetchTopTags() {
    try {
      this.topTagLoading = true
      if (this.userProfile?._id) {
        const res = yield apiService.tagFollows.getTopTagsProfile(this.userProfile._id)
        if (res) this.topTags = res
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.topTagLoading = false
    }
  }

  @asyncAction *fetchTopLink() {
    try {
      this.topLinkLoading = true
      this.topLinks = yield apiService.links.fetchTopLinks({ profile: this.userProfile?._id })
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.topLinkLoading = false
    }
  }

  @asyncAction *fetchDaosOwned() {
    try {
      this.daoOwnedFetching = true
      if (this.userProfile?._id) {
        const daos = yield apiService.daos.find({ profile: this.userProfile?._id, deleted: false })
        this.daosOwned = daos
      }
    } catch (error) {
    } finally {
      this.daoOwnedFetching = false
    }
  }

  @asyncAction *fetchDaosContributed() {
    try {
      this.daoContributedFetching = true
      if (this.userProfile?._id) {
        const userDaos = yield apiService.userDao.find({
          role: 'contributor',
          profile: this.userProfile?._id,
          // 'dao.deleted': false,
        })
        const daos = userDaos.map((userDao) => userDao.dao)
        this.daoContributed = daos.filter((item) => item.deleted === false)
      }
    } catch (error) {
    } finally {
      this.daoContributedFetching = false
    }
  }

  @asyncAction *followUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *followTag(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.tagFollows.followTag({ tag: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowTag(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.tagFollows.unFollowTag({ tag: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  // @asyncAction *fetchLikeInformation() {
  //   try {
  //     const givenLike = yield apiService.likes.count({ profile: this.userInfo?.profile?._id })
  //     const receivedLike = yield apiService.likes.count({ 'post.profile': this.userInfo?.profile?._id })
  //     if (givenLike) this.givenLike = givenLike
  //     if (receivedLike) this.receivedLike = receivedLike
  //   } catch (error) {
  //     //
  //   }
  // }

  @computed get totalPost() {
    return this.userProfile?.totalPosts || 0
  }

  @computed get likeCount() {
    return Math.round(this.givenLike * 0.3 + this.receivedLike * 0.7)
  }
}
