









import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { SummaryViewModel } from '../viewmodels/summary-viewmodel'

@Observer
@Component({
  components: {
    stats: () => import('../components/summary/stats.vue'),
    'top-container': () => import('../components/summary/top-container.vue'),
    'top-post': () => import('../components/summary/top-post.vue'),
    'dao-area': () => import('@/modules/profile/components/summary/components/dao-area.vue'),
  },
})
export default class Summary extends Vue {
  @Provide() vm = new SummaryViewModel()
  @Watch('$route.params.userId', { immediate: true }) userChange() {
    this.vm.init()
  }

  destroyed() {
    this.vm.destroy()
  }
}
