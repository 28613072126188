import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { PostStore } from './post-store'
import { walletStore } from './wallet-store'

export class PostsStore {
  @observable posts: PostStore[] = []
  @observable filter: any = { _limit: 10, _start: 0 }
  @observable total = 0
  @observable loadingMore = false
  @observable loadState: any = { _limit: 10, _start: 0 }
  @observable fetching = false
  @observable isMyFeed = false

  private _disposers: IReactionDisposer[]

  constructor(filter, isMyFeed = false) {
    if (!walletStore.isLogin) {
      this.filter = { ...this.filter, isBlind: false }
      this.filter = { ...this.loadState, isBlind: false }
    }
    this.isMyFeed = isMyFeed

    this.fetchPosts({ ...this.filter, ...filter })
    this.loadState = { ...this.loadState, ...filter }
    this._disposers = [
      reaction(
        () => this.filter,
        (filter) => {
          this.fetchPosts(filter)
          this.changeLoadState({ ...filter, _limit: 10, _start: 0 })
        }
      ),
    ]
  }
  destroy() {
    this.posts.forEach((postStore) => postStore.destroy())
    this._disposers.forEach((d) => d())
  }

  @action.bound updateList() {
    this.fetchPosts(this.filter)
    this.changeLoadState({ ...this.filter, _limit: 10, _start: 0 })
  }

  @action.bound changeFilter(filter, isMyFeed = false) {
    this.isMyFeed = isMyFeed
    this.filter = { ...filter, _limit: 10, _start: 0 }
  }

  @action.bound changeLoadState(filter) {
    this.loadState = { ...this.loadState, ...filter }
  }

  @asyncAction *fetchPosts(filter: any) {
    try {
      this.fetching = true
      let posts = [] as any
      if (this.isMyFeed) {
        const res = yield apiService.posts.fetchMyFeed({ ...this.filter, ...filter })
        this.total = res.total
        posts = res.posts
      } else {
        posts = yield apiService.posts.fetchPosts({ ...this.filter, ...filter })
        this.total = posts[0]?.total || 0
      }
      this.posts = posts.map((post) => {
        const postStore = new PostStore(post)
        return postStore
      })
      return posts
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @asyncAction *loadMore() {
    if (this.loadingMore) return
    try {
      this.loadingMore = true
      this.loadState = { ...this.loadState, _start: this.loadState._start + this.loadState._limit }
      let postMore = [] as any
      if (this.isMyFeed) {
        const res = yield apiService.posts.fetchMyFeed({ ...this.loadState })
        postMore = res.posts
      } else {
        postMore = yield apiService.posts.fetchPosts({ ...this.loadState })
      }

      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.posts
      this.posts = [...currentPost, ...newPost]
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }

  @computed get canLoadMorePost() {
    if (this.posts.length < this.total) return true
    else return false
  }
}
